<template>
    <div class="template-page grey-bg event-page">


        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">

            <div v-if="ongoingEvent.length > 0" >
                <div v-for="(event, index) in ongoingEvent" :key="index">
                    <router-link class="main-event" :to="eventLink(event)" v-if="event">
                        <main-event-tile :eventdata="event"></main-event-tile>
                    </router-link>
                </div> 
            </div>


            <div class="container-events" v-if="upcomingEvents.length > 0">
                <h2 class="title-section">
                    Upcoming Events
                </h2>
                <div class="events">
                    <router-link :to="eventLink(event)" v-for="(event, index) in this.upcoming" :key="'event'+index">
                        <event-tile :eventdata="event"></event-tile>
                    </router-link>
                </div>
            </div>

            <div class="container-events past" v-if="this.loadedPastEvents.length > 0">
                <h2 class="title-section">
                    Past Events
                </h2>
                <div class="events">
                    <router-link :to="eventLink(event)" v-for="(event, index) in this.loadedPastEvents" :key="'event'+index">
                        <event-tile :eventdata="event"></event-tile>
                    </router-link>
                </div>

                <br>
                <div class="loadmore" v-if="this.pastEvents.length > this.loaded">
                    <button class="cta" @click="loadMore()"><span>Load More</span></button>
                </div>
            </div>

        </basic-page>


    </div>
</template>

<script>
import BasicPage from '../views/BasicPage'
import EventTile from '../components/EventTile.vue'
import MainEventTile from '../components/MainEventTile.vue'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
import { detailsLink } from '../libs/utils'
export default {
    name: 'events-fot-the-public',
    components: {
        BasicPage, EventTile, MainEventTile
    },
    data: () => {
        return {
            eventsData: [],
            upcomingEvents: [],
            pastEvents: [],
            pageData: {},
            fulldata: {},
            currentPageId: "582f3cd5-90fd-4c23-a502-2a6b90c9a533",
            loaded: 6,
            bannerimg: null
        }
    },
    methods: {
        eventLink(e) {
            return detailsLink('event', e)
        },
        loadMore() {
            this.loaded += 6
        }
    },
    computed: {
        loadedPastEvents() {
            return this.pastEvents.slice(0, this.loaded)
        },
        ongoingEvent() {
            return this.upcomingEvents.filter(e => 
                Date.parse(e.attributes.field_event_start_date) < Date.now() && Date.parse(e.attributes.field_event_end_date) > Date.now()
            ).sort((a, b) => {
                return ( Date.parse(a.attributes.field_event_start_date) - Date.parse(b.attributes.field_event_start_date)  ) 
            })
        },
        upcoming() {

            return this.upcomingEvents.filter(e => Date.parse(e.attributes.field_event_start_date) > Date.now() ).sort((a, b) => {
                return ( Date.parse(a.attributes.field_event_start_date) - Date.parse(b.attributes.field_event_start_date)  ) 
            })

        }
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('event', {
            include: ['field_event_image']
        }).then(res => {
            this.eventsData = res

            this.pastEvents = res.filter(e => Date.parse(e.attributes.field_event_end_date) < Date.now() )
                .sort((a, b) => {
                    return (Date.parse(a.attributes.field_event_start_date) > Date.parse(b.attributes.field_event_start_date)) ? -1 : 1
                })

            this.upcomingEvents = res.filter(e => Date.parse(e.attributes.field_event_end_date) > Date.now() ).sort((a, b) => {
                return ( Date.parse(a.attributes.field_event_start_date) - Date.parse(b.attributes.field_event_start_date)  ) 
            })
            
        })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

.yellow-angle {
    &::after {
        top: 15px;
    }
}




.container-events  {
    background-color: #fff;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        width: 150vw;
        height: 100%;
        position: absolute;
        top: 0%;
        left: -30%;
        background-color: rgb(255, 255, 255);
        z-index: 0;
        padding-bottom: 30px;
    }
    .events {
        position: relative;
        z-index: 1;
    }
}


h2 {
    margin: 40px 0;
}

.main-event {
    text-decoration: none;
    margin-bottom: 3rem;
    display: block;
}

.events {
    display: flex; display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    
    > a {
        text-decoration: none;
        display: block;
        width: 33.33%;
        margin: 0;
        margin-bottom: 40px;
        padding-bottom: 40px;
       
    }
}

@media (max-width:992px){

    .events > a{
        width: 100%;
        border-bottom: 1px solid rgb(200, 200, 200);
            &:last-child{
                border-bottom: 0px ;
            }
        .event-wrapper {
            padding: 0px;
            border-right: 0px solid #F0F0F0;
        }
    }
}

@media (max-width:576px){
    .yellow-angle::after {
            top: 0px;
        right: 0px;
    }
    .event-wrapper .content .info,
    .event-wrapper .content .date {
        width: auto;
    }
        .event-wrapper .content .info {
            padding-right: 45px;
    }
}
</style>